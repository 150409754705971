import React from "react";
import Footer from "src/components/Footer_uk";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/uk/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/uk/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/uk/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/allergy/">Allergy</a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">After sun</a>
                      </li>                      
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/where-to-buy/" target="_self">
                      Where to buy
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/tan_protect/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/tan_protect/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt="Enhances natural tanning"
                    src="/assets/images/tan-protect-uk.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/tan-protect-mobile-uk.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN TAN &amp; PROTECT<sup>®</sup>
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Enhances natural tanning</h3>
                      <p>
                        Whether you’re just getting a burst of sun on a weekend
                        city break or planning to hit the beach for a long,
                        relaxing holiday, PIZ BUIN TAN &amp; PROTECT<sup>®</sup>{" "}
                        Oils, Lotions and Sprays, will help you get the most out
                        of your time in the sun. PIZ BUIN TAN &amp; PROTECT
                        <sup>®</sup> combines effective UVA/UVB protection with
                        Illumitone<sup>™</sup>. By increasing the production of
                        the skin’s tanning pigment by up to 70%*, Illumitone
                        <sup>™</sup> is proven to accelerate the speed of the
                        natural tanning process** - for a naturally faster***,
                        more beautiful tan.
                      </p>
                      <p className="footNote">
                        *Sprays and Lotions, in vitro tested , ** In vitro test
                        , ***Contains a technology that enhances natural tanning
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="tan-accelerating-oil-spray" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-tan-protect-tan-intensifying-sun-oil-spray-30spf-150ml.png" alt="Enhances natural tanning" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>TAN ACCELERATING OIL SPRAY</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          Our tan accelerating oil spray helps you to get a
                          safe, gorgeous colour quickly – allowing you to make
                          every moment in the sun count this summer. Traditional
                          tanning oils may make your tan look fantastic but
                          offer little or no protection, while ordinary high SPF
                          sun creams can mean having to wait too long to get the
                          summer colour you’re after. So how do you get a
                          gorgeous, natural tan faster without compromising on
                          protecting your skin?
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Tan
                            Accelerating Oil Spray is just what you need. Our
                            fast absorbing, non-greasy, easy to apply oil
                            combines effective UVA and UVB protection with
                            Melitane<sup>™</sup>, an innovative ingredient that
                            speeds up your skin’s natural tanning process*. The
                            fast absorbing oil nourishes the skin leaving it
                            beautifully smooth and glossy. PIZ BUIN TAN &amp;
                            PROTECT<sup>®</sup> Tan Accelerating Oil Spray. For
                            a faster more beautiful tan ... safely!
                          </p>
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Tan
                            Accelerating Oil Spray is sweat and water resistant.
                          </p>
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Tan
                            Accelerating Oil Spray in favour with consumers. We
                            asked consumers to tell us what they thought of the
                            tan accelerating oil spray. After four weeks of
                            testing…
                          </p>
                          <p>
                            <strong className="orange">80%**</strong>{" "}
                            experienced:
                          </p>
                          <ul>
                            <li>An enhanced natural tan</li>
                          </ul>
                          <p>
                            <strong className="orange">84%***</strong>{" "}
                            experienced:
                          </p>
                          <ul>
                            <li>A beautiful, glossy shine</li>
                          </ul>
                          <p>
                            <strong className="orange">97%**</strong>{" "}
                            experienced:
                          </p>
                          <ul>
                            <li>Effective sun protection</li>
                          </ul>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Read more
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight. Do not spray directly into the face. For use
                          on face, spray into hands and apply avoiding contact
                          with eyes.
                        </p>
                        <p className="footNote">*In vitro test</p>
                        <p className="footNote">
                            &nbsp; **Self-assessment results obtained on the SPF 30
                          Spray, 89 subjects, 4 weeks, at least once a day
                          application.
                        </p>
                        <p className="footNote">
                          *** Self-assessment results obtained on the SPF30
                          Spray, 40 subjects who used the body oil whilst
                          sunbathing, 4 weeks, at least once a day application
                        </p>
                        <div> </div>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" alt="ADVANCED UVA/UVB SUN FILTER SYSTEM" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMIN E
                                <div className="textBlock bottom">
                                  <p>
                                    Vitamin E is a powerful natural antioxidant
                                    that helps to protect from free radicals,
                                    which damage skin cells* and cause premature
                                    skin ageing.
                                    <br />
                                    <span className="footNote">
                                      <br />* In vitro
                                      <br />
                                      <br />
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" alt="VITAMIN E" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Illumitone™
                                <div className="textBlock bottom">
                                  <p>
                                    Illumitone™ is proven to enhance the natural
                                    tanning process. It increases the production
                                    of the body’s natural tanning pigment
                                    (melanin) by up to 70%*. Illumitone™ does
                                    not stain clothes, swimsuits and towels.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *Sprays and Lotions, in vitro test
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/226770468d322e412d20107058a97d32_f541.png" alt="Illumitone™" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div className="products-page__product__details__where">
                        <a
                          href="/uk/where-to-buy/"
                          className="btn btn--uppercase"
                        >
                          Where To Buy
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="tan-intensifying-sun-lotion" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/PB_TanProtect_Tube_15ml_SPF15.jpg" alt="TAN INTENSIFYING SUN LOTION" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>TAN INTENSIFYING SUN LOTION</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          The non-greasy and non-sticky formula of the PIZ BUIN
                          TAN &amp; PROTECT<sup>®</sup> Tan Intensifying Sun
                          Lotion, intensely moisturises your skin to help
                          prevent peeling and contribute to a longer lasting
                          tan.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Tan
                            Intensifying Sun Lotion is sweat and water
                            resistant.
                          </p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Read more
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor15" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" alt="ADVANCED UVA/UVB SUN FILTER SYSTEM" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMIN E
                                <div className="textBlock bottom">
                                  <p>
                                    Vitamin E is a powerful natural antioxidant
                                    that helps to protect from free radicals,
                                    which damage skin cells* and cause premature
                                    skin ageing.
                                    <br />
                                    <span className="footNote">
                                      <br />* In vitro
                                      <br />
                                      <br />
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" alt="VITAMIN E" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Illumitone™
                                <div className="textBlock bottom">
                                  <p>
                                    Illumitone™ is proven to enhance the natural
                                    tanning process. It increases the production
                                    of the body’s natural tanning pigment
                                    (melanin) by up to 70%*. Illumitone™ does
                                    not stain clothes, swimsuits and towels.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *Sprays and Lotions, in vitro test
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/226770468d322e412d20107058a97d32_f541.png" alt="Illumitone™" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div className="products-page__product__details__where">
                        <a
                          href="/uk/where-to-buy/"
                          className="btn btn--uppercase"
                        >
                          Where To Buy
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="tan-intensifying-sun-spray" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-tan-protect-tan-intensifying-sun-spray-30spf-150ml.jpg" alt="Enhances natural tanning" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>TAN INTENSIFYING SUN SPRAY</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          Thanks to its ultra light non-greasy, non-sticky
                          formula, our PIZ BUIN TAN &amp; PROTECT<sup>®</sup>{" "}
                          Tan Intensifying Sun Spray absorbs instantly,
                          hydrating your skin in order to help prevent peeling
                          and contribute to a longer lasting tan.
                          <br />
                          PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Tan
                          Intensifying Sun Spray is sweat and water resistant.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight. Do not spray directly into the face. For use
                          on face, spray into hands and apply avoiding contact
                          with eyes.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" alt="ADVANCED UVA/UVB SUN FILTER SYSTEM" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMIN E
                                <div className="textBlock bottom">
                                  <p>
                                    Vitamin E is a powerful natural antioxidant
                                    that helps to protect from free radicals,
                                    which damage skin cells* and cause premature
                                    skin ageing.
                                    <br />
                                    <span className="footNote">
                                      <br />* In vitro
                                      <br />
                                      <br />
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" alt="VITAMIN E" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Illumitone™
                                <div className="textBlock bottom">
                                  <p>
                                    Illumitone™ is proven to enhance the natural
                                    tanning process. It increases the production
                                    of the body’s natural tanning pigment
                                    (melanin) by up to 70%*. Illumitone™ does
                                    not stain clothes, swimsuits and towels.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *Sprays and Lotions, in vitro test
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/226770468d322e412d20107058a97d32_f541.png" alt="Illumitone™" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div className="products-page__product__details__where">
                        <a
                          href="/uk/where-to-buy/"
                          className="btn btn--uppercase"
                        >
                          Where To Buy
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/uk/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/ultra_light/" target="_self">
                      Ultra Light
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/uk/our-products/tan_protect/"
                      target="_self"
                    >
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/onedaylong/" target="_self">
                      1 Day Long
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/aftersun/" target="_self">
                      After sun
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/all_products/" target="_self">
                      All products
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.instagram.com/PizBuin_UK/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Read More');\n} else {\nbox.slideDown();\n$(this).text('Read Less');\n}\n});\n});\n"
          }}
        />
        
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
